<template>
  <!-- <div style="width: 100%; height: 100%; position:absolute; top: 0; left: 0;">
    <div class="background-img" id="bg-te">
      <div class="loader">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
    <unity  src="/unity/Build/tedemo8.json" 
     
      :hideFooter="true"
      :external-progress="true"
      unityLoader="/unity/Build/UnityLoader.js"
      ref="unityInstance"
    ></unity>  -->

  <div style="width: 100%; height: 100%; position:absolute;">
    <div class="background-img" id="bg-se">
      <div class="loader">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
    <canvas id="unity-canvas" style="width: 1200px; height: 800px; background: #231F20" class="alturate"></canvas>  

    <v-dialog
      v-model="dialogIsMobile"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Teaching tools does not work properly on mobiles
        </v-card-title>

        <v-card-text>
          You can continue to use, but we recommend using it on desktop
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogIsMobile = false"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirm" persistent max-width="300">
      <v-card>
        <v-card-title class="text-h5">
          Deseas seguir?
        </v-card-title>
        <v-card-text>Si no selecciona nada, en un minuto aproximadamente saldra al Dashboard.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="confirmD(true)"
          >
            Si
          </v-btn>
          <v-btn
            color="grey darken-1"
            text
            @click="confirmD(false)"
          >
            No
          </v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Unity from 'vue-unity-webgl'
import { db } from '../../../plugins/firebase'
import JWTDecode from 'jwt-decode'
//import TimeMe from 'timeme.js'
// window.onbeforeunload = function (event) {
//   const xmlhttp=new XMLHttpRequest();
//   xmlhttp.open("POST","https://us-central1-leeleeadmin.cloudfunctions.net/timeGetTeacher", true);
//   xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
//   xmlhttp.send('time=25');
// };
//var timeout;
export default {
  components: { Unity },
	name: "ActivitiesList",
  data() {
      return {
        link : '',
        dialogIsMobile:false,
        id: '',
        role: '',
        startTime: 0,
        interval:'',
        timeout: '',
        timeoutC: '',
        enableMove: true,
        gradeList: ['Pre-School', 'Kindergarten', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', '5th Grade', '6th Grade', , '7th Grade', , '8th Grade'],
        dialogConfirm: false
      }
    },
    computed: {
      ...mapState('userTeacher', ['userTeacher'])
    },
    watch: {
      userTeacher: {
        handler(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.GetDataTeacher();
          }
        },
        deep: true,
        immediate: true
      }
    },
    created(){     
       window.addEventListener('beforeunload', this.enviarDatosSalir)
      // window.addEventListener('beforeunload', () => {
      //       //I CAN ACCESS TO this VARIABLE
      //    console.log('salida')
      //     if (this.role != '' && this.id != '') {
      //       let date = new Date();
      //       let time = date.getTime();
      //       db.collection('TimeUse').doc(this.id).set({ [`${[[time]]}`]: 2 }, { merge: true })
      //     }
            
      //   }, false)
    },
    methods: {
      // async probarToken () {
      //   const token = await auth().currentUser.getIdToken();
      //   console.log(token)
      //   const decoded = JWTDecode(token);
      //   console.log(decoded)
      //   //console.log(Math.floor(Date.now()+ ( 3600 * 1000 * 24) / 1000))

      // },
      // async EnviarFirebase () {
      //   const token = await auth().currentUser.getIdToken();
      //   console.log(token)
      //   // const response = await fetch('https://us-central1-leeleeadmin.cloudfunctions.net/verifyTokenTE/'+token);
      //   // const myJson = await response.json(); //extract JSON from the http response
      //   // do something with myJson
      //   window.location.href = "leelee://game?token="+token;
      // },
      getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        let answer = false;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
          console.log("Windows Phone");
          answer=true
        }
        if (/Android/i.test(userAgent)) {
          console.log("Android");
          answer= true;
        }
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          console.log("iOS");
          answer = true;
        }
        return answer;
      },
      /*enviarDatosSalir(){
        this.enableMove = false;
        clearTimeout(this.timeout);
        if (this.startTime != 0 && this.id != '') {
          let date = new Date();
          let ActualTime = date.getTime();
          let InitialTime = this.startTime.getTime()
          let seconds = Math.round((ActualTime - InitialTime) / 1000);

          const xmlhttp=new XMLHttpRequest();
          xmlhttp.open("POST","https://us-central1-leeleeadmin2022.cloudfunctions.net/timeGetTeacher", true);
          xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
          xmlhttp.send('time='+seconds+'&starttime='+InitialTime+'&id='+this.id);
        }
      },*/
      confirmD(opt){        
        if (opt){
          clearTimeout(this.timeoutC);
        }
        this.dialogConfirm=false;
      },
      /*tiempoInactividadSalir(){
        let me = this;
        this.timeout = setTimeout(function(){
          console.log("se acabo el tiempo de espera");
          let date = new Date();
          let time = date.getTime();
          me.dialogConfirm=true;
          me.timeoutC=setTimeout(function(){ 
            me.dialogConfirm=false;
            let seconds = (time - me.startTime.getTime()) / 1000;
            if (seconds > 10) {
              db.collection('TimeUse').doc(me.id).set({ [`${[[me.startTime.getTime()]]}`]: seconds }, { merge: true }).then(()=>{
                window.location.href = '/login';
              });
            }
          },60000);         
        },1200000);
      }*/
    },
    async mounted() {
        var elemento = document.querySelectorAll(".alturate");
        var height = window.innerHeight;
        var width = window.innerWidth;
        elemento[0].style.height=height+'px';
        elemento[0].style.width=width+'px';
        var unityGame;        
        let L1=false;
        let L2=false;
        let InlcudeTEKS=false;
        if (this.$store.state.user.role != 'individualTeacher') {
          let DataTeacher = await  db.collection("SchoolTeacher").doc(this.$store.state.user.uid).get();
          const Teacher=DataTeacher.data();
          let idState = Teacher.SchoolReference.parent.parent.parent.parent.id;
          if (idState == 'TpFpRZodvG40D5kmvE5z') {
            InlcudeTEKS=true;
          }
          // if (Object.prototype.hasOwnProperty.call(Teacher, 'LeeLeeV2') && Teacher.LeeLeeV2) {
          //   L2=true;
          // }

          // cambiar esta logica para que se agreguen los datos de grado al documento maestro
          // Verificamos en que niveles tiene clases este maestro
          const Classrooms=Teacher.Classrooms;
          let idsClassrooms = Object.keys(Classrooms);
          let position=-1;
          for (const idClassRoom of idsClassrooms) {            
            console.log(Classrooms[idClassRoom].Grade);
            position=this.gradeList.indexOf(Classrooms[idClassRoom].Grade);
            if (position>=0 && position<=3){
              L1=true;
            }else if (position>=4 && position<=9){
              L2=true;
            }
          }
          if (idsClassrooms.length===0 && Object.prototype.hasOwnProperty.call(Teacher, "GradeRegistered")) {
            position=this.gradeList.indexOf(Teacher.GradeRegistered);
            if (position>=0 && position<=3){
              L1=true;
            }else if (position>=4 && position<=9){
              L2=true;
            }
          }
        }
        let responsiveScript = document.createElement('script')
        responsiveScript.setAttribute('src', '/TeV2/Build/TE15092024a.loader.js')
        document.body.appendChild(responsiveScript)
        responsiveScript.onload = () => {
          createUnityInstance(document.querySelector("#unity-canvas"), {
            dataUrl: "/TeV2/Build/TE15092024a.data",
            frameworkUrl: "/TeV2/Build/TE15092024a.framework.js",
            codeUrl: "/TeV2/Build/TE15092024a.wasm",
            streamingAssetsUrl: "/TeV2/StreamingAssets",
            companyName: "escueladelectura",
            productName: "LeeLee TE",
            productVersion: "1.0.8",
          })
          .then((instance) =>
          {
            window.unityInstance = instance;            
            unityGame = instance;
            let me = this;
            var bg = document.getElementById('bg-se');
            if(bg != null){
              bg.classList.add('d-none');
            }
            

            let tipoTE = L1 && L2? 'Ambos' : ( L2? 'AE' : 'AAE' );
            let mostrarTEKS = (InlcudeTEKS) ? 'true' : 'false'; // (true) poner cuando el maestro es del distrito de Texas
            setTimeout(function () { unityInstance.SendMessage('GestorTE', 'SelectTypeTE', tipoTE ); }, 1000); // Ambos, AAE, AE
            setTimeout(function () { unityInstance.SendMessage('GestorTE', 'MostrarTEKS', mostrarTEKS ); }, 1000);
          })
          .catch((message) => { console.warn(message); });
        }
        
        let isMobile = this.getMobileOperatingSystem();
        if (isMobile) {
          this.dialogIsMobile = true;
        }
        this.id = this.$store.state.user.uid;
        let role = this.$store.state.user.role;
        if (role != 'IndividualTeacher') {
          role = 'SchoolTeacher';
        }
        this.role = role;
        /*let beginDate = new Date();
        this.startTime = beginDate;
        let seconds = 0;
        let me = this;
        let minutos = 15
        this.interval = setInterval(function() {
          seconds += (minutos*60)
          db.collection('TimeUse').doc(me.id).set({ [`${[[me.startTime.getTime()]]}`]: seconds }, { merge: true })
        }, ((minutos*60)*1000));
      

      document.onmousemove = function () {
        if(!me.enableMove) return;
        clearTimeout(me.timeout);
        console.log("movio el mouse");
        me.tiempoInactividadSalir();
      }*/
    },
    beforeDestroy(){
      //console.log('salio')
      //clearInterval(this.interval);
      /*if (this.role != '' && this.id != '' && this.startTime != 0) {
        let date = new Date();
        let time = date.getTime();
        let seconds = (time - this.startTime.getTime()) / 1000;
        if (seconds > 10) {
          db.collection('TimeUse').doc(this.id).set({ [`${[[this.startTime.getTime()]]}`]: seconds }, { merge: true })
        }
      }
      this.enableMove = false;*/
      //clearTimeout(this.timeout);


      /************** */
      /*if (window.unityInstance) {console.log("Entramos a borrar memo...");console.log(window.unityInstance);
        // Detén el juego Unity.
        window.unityInstance.Quit().then(function () {
          console.log('Juego Unity detenido.');
          
          // Elimina la instancia del juego Unity.
          window.unityInstance = null;

          // Elimina la referencia al elemento del DOM que contiene el juego.
          const unityCanvas = document.querySelector("#unity-canvas");
          unityCanvas.parentNode.removeChild(unityCanvas);

          // Realiza cualquier otra limpieza necesaria.
        });
      }*/
    },
    
    // beforeDestroy(){
    //   if (this.role != '' && this.id != '' && this.startTime != 0) {
    //     let date = new Date();
    //     let time = date.getTime();
    //     let seconds = (time - this.startTime.getTime()) / 1000;
    //     if (seconds > 10) {
    //       db.collection('TimeUse').doc(this.id).set({ [`${[[time]]}`]: seconds }, { merge: true })
    //     }
        
    //   }
    // }
}
</script>

<style scoped>
  @font-face {
    font-family: "Student";
    src: local("Student"),
    url('../../../assets/fonts/Little Student.otf') format("truetype");
  }
  /*.texto-entrada-te h2{
    font-family: "Student", Helvetica, Arial;
    font-size: 70px;
    padding-top: 20px;
    color: #3f7aff;
    text-shadow: 3px 3px 2px #b9b9b9;
    text-align: center;
  }
  .texto-entrada-footer-te h2{
    font-family: "Student", Helvetica, Arial;
    font-size: 70px;
    color: #3f7aff;
    text-shadow: 3px 3px 2px #b9b9b9;
    text-align: center;
  }
  @media screen and (min-width: 1400px) {
    .texto-entrada-te h2{
      font-size: 80px;
      padding-top: 80px;
      padding-bottom: 40px;
    }
    .texto-entrada-footer-te h2{
      font-size: 80px;
      padding-top: 60px;
    }
    .img-list img{
      height: 270px;
    }
  }
  .img-list img{
    cursor: pointer;
    margin: 0 -1px;
    height: 250px;
  }
  .tarjeta:hover{
    background-color: #03a9f4 !important;
  }
  .fondo-maestro-principal{
    background-image: url('../../../assets/admin/fondo-misiones.gif');
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .fade-in {
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s;
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
*/
  .enlace {
    font-size: 1.7em;
    font-family: cursive;
  }
  .download{
    width: 940px;
    font-size: 1.1em;
  }
  .background-img{
    width: 100%;
    height: calc(100% - 50px);
    background-image: url('/AperturaLeeLee.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: fixed;
    right: 0;
    top: 45px;
    display: flex;
    z-index: 1;
  }
  .loader{
    position: absolute;
    bottom: 50px;
    left: 50%;
  }
</style>